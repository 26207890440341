import styles from './Info.module.scss';

export default function Info({ title, description, modifier, content, children }) {
    return (
        <section className={styles['info'] + (modifier ? ' ' + modifier : '')}>
            {title && <h2 className="info__title">{title}</h2>}
            {content}
            {description && <p className="info__description">{description}</p>}
            {children}
        </section>
    );
}
