import Icon from '@/components/icon/Icon';
import { getPriceText } from '@/src/helpers/FormatHelper';

/**
 * Renders a <DetailsOffer /> component
 * @param {Subscription} model
 */
export function DetailsOffer({ model }) {
    const prefixPrice = model.offers.offer?.stock?.isTNB() ? 'далее ' : '';
    if (!model.offers.offer) {
        return '';
    }
    return model.isAvailable() ? (
        <div className="info__more">
            <div className="info__more-chip">
                <span className="info__more-text">{getPriceInfo(model)}</span>
            </div>
        </div>
    ) : (
        <div className="info__more">
            <div className="info__more-price">
                <Icon id={'wallet'} modifier={'icon--wallet info__more-icon'} />
                <span className="info__more-text">
                    {prefixPrice}
                    {getPriceInfo(model)}
                </span>
            </div>
        </div>
    );
}

/**
 * @param {Subscription} model
 */
const getPriceInfo = (model) => {
    const offer = model?.offers?.offer;
    if (!offer) {
        return '';
    }
    if (model.isAvailable()) {
        switch (true) {
            case offer.isPartOfBundle(model):
                return `в подписке ${offer.subscription?.title}`;
            case model?.offers?.isTNBActivated():
                return 'Пробный период';
            case offer.price === 0:
                return 'Доступно';
            case !offer.price:
                return '';
            default:
                return 'Подключено';
        }
    } else {
        if (offer.price === 0) {
            return 'Доступно';
        } else if (!offer.price) {
            return '';
        } else {
            return model.offers.offer?.stock?.isTNB()
                ? getPriceText(offer.price, 1, 'month')
                : getPriceText(offer.price, offer.period, offer.periodType);
        }
    }
};
