import BaseModel from '@/src/api/v3/models/BaseModel';
import Asset from '@/src/api/v3/models/Asset';
import Person from '@/src/api/v3/models/Person';
import Schedule from '@/src/api/v3/models/Schedule';
import Subscription from '@/src/api/v3/models/Subscription';
import Bundle from '@/src/api/v3/models/Bundle';
import Compilation from '@/src/api/v3/models/Compilation';
import TvShow from '@/src/api/v3/models/TvShow';
import Genre from '@/src/api/v3/models/Genre';

import AssetDetails from '@/components/v3/details/AssetDetails';
import PersonDetails from '@/components/v3/details/PersonDetails';
import ScheduleDetails from '@/components/v3/details/ScheduleDetails';
import SubscriptionDetails from '@/components/v3/details/SubscriptionDetails';
import BundleDetails from '@/components/v3/details/BundleDetails';
import CompilationDetails from '@/components/v3/details/CompilationDetails';
import TvShowDetails from '@/components/v3/details/TvShowDetails';
import BaseDetails from '@/components/v3/details/BaseDetails';

export default function Details({ model }) {
    if (model instanceof Asset) {
        return <AssetDetails model={model} />;
    }
    if (model instanceof Person) {
        return <PersonDetails model={model} />;
    }
    if (model instanceof Schedule) {
        return <ScheduleDetails model={model} />;
    }
    if (model instanceof Bundle) {
        return <BundleDetails model={model} />;
    }
    if (model instanceof Subscription) {
        return <SubscriptionDetails model={model} />;
    }
    if (model instanceof Compilation || model instanceof Genre) {
        return <CompilationDetails model={model} />;
    }
    if (model instanceof TvShow) {
        return <TvShowDetails model={model} />;
    }
    if (model instanceof BaseModel) {
        return <BaseDetails model={model} />;
    }
    return '';
}
