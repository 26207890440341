import { ScoresTypes } from '@/src/api/v3/models/Score';
import { getPersonsMarkup } from '@/src/api/v3/models/helpers/MarkupHelper';

/**
 * @see https://schema.org/TVSeries
 * @param {Schedule} model
 */
export const ScheduleMarkup = (model) => {
    const program = model.program;
    let markup = {
        '@context': 'https://schema.org',
        '@type': 'TVSeries',
        name: model.title,
        description: program.description,
        url: 'https://movix.ru' + model.getUrl(),
        image: program.getImageSrc({ size: { width: 284, height: 160 } }),
        datePublished: program.releasedYear,
        genre: program.genres,
        contentRating: `${model.age}+`,
        countryOfOrigin: program.countries,
        containsSeason: {
            '@type': 'TVSeason',
            episode: {
                '@type': 'TVEpisode',
                position: program.episodeNumber,
                publication: [
                    {
                        '@type': 'BroadcastEvent',
                        publishedOn: {
                            '@type': 'BroadcastService',
                            url: 'https://movix.ru' + model.getLiveUrl(),
                        },
                        startDate: model.startAt.toISOString(),
                    },
                ],
                url: 'https://movix.ru' + model.getUrl(),
                ...(model.isCatchupAvailable
                    ? {
                          video: {
                              '@type': 'VideoObject',
                              url: 'https://movix.ru' + model.getCatchUpUrl(),
                          },
                      }
                    : {}),
            },
        },
    };
    const personMarkup = program.persons.length > 0 ? getPersonsMarkup(program.persons[0].items) : {};
    if (program.scores[ScoresTypes.SCORE_KINOPOISK]) {
        markup.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: program.scores[ScoresTypes.SCORE_KINOPOISK],
        };
    }

    return {
        ...markup,
        ...personMarkup,
    };
};
