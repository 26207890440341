import Info from '@/components/info/Info';

/**
 * Renders a <BaseDetails /> component
 * @param props
 * @param {BaseModel} props.model
 */
export default function BaseDetails({ model }) {
    return <Info title={model.title} />;
}
