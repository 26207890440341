import Head from 'next/head';
import Backbg from '@/components/backbg/Backbg';
import Showcases from '@/components/v3/Showcases';
import Details from '@/components/v3/Details';
import React, { useState } from 'react';
import Pagination from '@/components/pagination/Pagination';
import { RenderType } from '@/src/api/v3/models/Showcase';
import { getModelAction } from '@/components/v3/Action';
import { RootModelProvider } from '@/src/hooks/useRootModel';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import getModelMarkup from '@/src/api/v3/models/helpers/MarkupHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';

/**
 * @param props
 * @param {Page} props.page
 * @param {?JSX.Element} props.headerContent
 * @param {?JSX.Element} props.containerTopContent
 * @param {?JSX.Element} props.containerFooterContent
 */
export default function Page({
    page,
    headerContent = null,
    containerTopContent = null,
    containerFooterContent = null,
}) {
    const { api } = useApiStore();
    const [isLoading, setIsLoading] = useState(false);

    const onLoadItems = async () => {
        setIsLoading(true);
        await page.loadShowcases(api, 20);
        // Если витрины были в кеше, то без setTimeout перерисовка не происходит
        setTimeout(() => {
            setIsLoading(false);
        }, 0);
    };

    const model = page.getDetailsItem();
    const isSpaceSection =
        !(
            page.showcases.length &&
            [RenderType.HTML, RenderType.SLIDER_BIG].indexOf(page.showcases[0].renderType) !== -1
        ) && !containerTopContent;

    return model ? (
        <>
            <Model model={model} showcases={page.showcases} />
            {page.showcases.length < page.total && <Pagination isLoading={isLoading} onLoadItems={onLoadItems} />}
        </>
    ) : (
        <>
            {(page.pageTitle || page.pageDescription) && (
                <Head>
                    page.pageTitle && <title>{page.pageTitle}</title>
                    page.pageDescription && <meta name="description" content={page.pageDescription} />
                </Head>
            )}
            <Backbg className={'backbg--secondary'} />
            {headerContent}
            <div className="container container--wide">
                {isSpaceSection && <section className="info info--compact info--page-top" />}
                {containerTopContent}
                <Showcases showcases={page.showcases} />
                {page.showcases.length < page.total && <Pagination isLoading={isLoading} onLoadItems={onLoadItems} />}
                {containerFooterContent}
            </div>
        </>
    );
}

/**
 * @param props
 * @param {BaseModel} props.model
 */
function Model({ model, showcases }) {
    const pageTitle = model.pageTitle || `${model.title} — Смотреть онлайн в хорошем качестве на Дом.ру Movix`;
    const forceUpdate = useForceUpdate();
    const actionElement = getModelAction(model, showcases[1], forceUpdate);
    const jsonLd = getModelMarkup(model);
    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <link rel="canonical" href={'https://movix.ru' + model.getUrl()} />
                {jsonLd && (
                    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
                )}
            </Head>
            <Backbg
                src={model.getBackgroundSrc()}
                alt={model.title}
                className={model.backgroundModifier}
                action={actionElement}
            />
            <div className="container">
                <RootModelProvider rootModel={model} rootShowcase={showcases[1]} onShowcaseUpdate={forceUpdate}>
                    <Details model={model} />
                    {model.children && <Showcases showcases={model.children.showcases} />}
                </RootModelProvider>
                <Showcases showcases={showcases} />
            </div>
        </>
    );
}
