import Icon from '@/components/icon/Icon';
import Link from 'next/link';
import { useState } from 'react';

/**
 * @param props
 * @param {Person} props.person
 */
export default function Person({ person }) {
    const [href, setHref] = useState(person.getDetailsSrc());

    return (
        <li className="people__person-item">
            <Link className="people__person-link" href={person.getUrl()}>
                <div className="people__person-img">
                    <svg
                        width="56"
                        height="56"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-label={person.title}
                    >
                        <mask id="mask-8pv6xlihy00" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                            <path
                                d="M54.764 8.77c-.321-1.696-.948-3.241-1.817-4.483-.87-1.241-1.952-2.136-3.14-2.595C45.463 0 27.973 0 27.973 0S10.482.051 6.135 1.744c-1.187.458-2.269 1.353-3.138 2.595C2.127 5.58 1.5 7.126 1.179 8.82c-1.314 11.025-1.824 27.825.036 38.41.321 1.695.948 3.24 1.817 4.482.87 1.241 1.952 2.136 3.14 2.595C10.516 56 28.007 56 28.007 56s17.49 0 21.836-1.692c1.187-.459 2.27-1.354 3.139-2.595.87-1.242 1.496-2.787 1.817-4.482 1.386-11.041 1.814-27.831-.036-38.462z"
                                fill="#FFFFFF"
                            />
                        </mask>
                        <g mask="url(#mask-8pv6xlihy00)">
                            <path
                                d="M54.764 8.77c-.321-1.696-.948-3.241-1.817-4.483-.87-1.241-1.952-2.136-3.14-2.595C45.463 0 27.973 0 27.973 0S10.482.051 6.135 1.744c-1.187.458-2.269 1.353-3.138 2.595C2.127 5.58 1.5 7.126 1.179 8.82c-1.314 11.025-1.824 27.825.036 38.41.321 1.695.948 3.24 1.817 4.482.87 1.241 1.952 2.136 3.14 2.595C10.516 56 28.007 56 28.007 56s17.49 0 21.836-1.692c1.187-.459 2.27-1.354 3.139-2.595.87-1.242 1.496-2.787 1.817-4.482 1.386-11.041 1.814-27.831-.036-38.462z"
                                fill="#16163E"
                            ></path>
                            <image
                                href={href}
                                onError={() => /* NOSONAR, false positive for Invalid property */ {
                                    const stub = person.resources.getStubUrl('stub-226_226.png');
                                    if (href !== stub) {
                                        setHref(stub);
                                    }
                                }}
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMinYMin slice"
                            />
                        </g>
                    </svg>
                </div>
                <div className="people__person-wrap">
                    <span className="people__person-name">{person.title}</span>
                    <div className="people__person-post">{person.getRolesTitles()}</div>
                </div>
                <div className="people__person-action">
                    <Icon id={'arrow-right'} modifier={'icon--small'} />
                </div>
            </Link>
        </li>
    );
}
