import Info from '@/components/info/Info';
import Icon from '@/components/icon/Icon';
import DetailStatus from '@/components/detail-status/DetailStatus';

/**
 * Renders a <TvShowDetails /> component
 * @param props
 * @param {TvShow} props.model
 */
export default function TvShowDetails({ model }) {
    return (
        <Info
            title={
                <>
                    {model.title} <Icon id={`age-${model.age}`} modifier={'icon--age info__age'} />
                </>
            }
            content={
                <>
                    <div className="info__genre">{model.genres.join(', ')}</div>
                    <DetailStatus scores={model.scores} modifier={'info__status'} />
                </>
            }
            description={model.description}
            modifier={'info--subscription'}
        />
    );
}
