import Info from '@/components/info/Info';

/**
 * Renders a <CompilationDetails /> component
 * @param props
 * @param {Compilation} props.model
 */
export default function CompilationDetails({ model }) {
    return (
        <Info
            title={model.title}
            description={model.description}
            modifier={
                'info--compact info--page-top h-mt--160 h-mt--tablet-120 h-mt--mobile-80 h-mb--60 h-mb--b-tablet-52 h-mb--mobile-42'
            }
        />
    );
}
