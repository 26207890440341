import Button from '@/components/button/Button';
import useModals from '@/src/hooks/useModals';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import AuthPinChecker from '@/src/checkers/AuthPinChecker';
import { clickButtonEvent } from '@/src/modules/events/GTM';

/**
 * Renders a <AssetAction /> component
 * @param props
 * @param {Asset} props.model
 */
export default function AssetAction({ model, showcase, onShowcaseUpdate }) {
    const { openModal } = useModals();
    const { pinStatus, checkIsPinExpired } = usePinStore();

    const openPlayer = async () => {
        const ModalPlayer = (await import('@/components/modal/player/ModalPlayer')).default;
        openModal(
            <ModalPlayer
                asset={model}
                rootModel={model}
                rootShowcase={showcase}
                onShowcaseUpdate={onShowcaseUpdate}
                isTrailer={true}
            />
        );
    };

    const getViewPinReason = () => {
        return model.getViewPinReason(pinStatus, checkIsPinExpired());
    };

    return (
        model.getTrailerSrc() && (
            <AuthPinChecker
                checked={getViewPinReason}
                forceAuthCheck={getViewPinReason()}
                capturedEventType={'onClickCapture'}
                backgroundSrc={model.getBackgroundSrc()}
                onBeforeCheck={() => {
                    clickButtonEvent('watchTrailer');
                }}
            >
                <Button
                    type={'button'}
                    textAtUpperLevel={'Трейлер'}
                    icon={'play'}
                    className={'button--trailer'}
                    iconClassName={'icon--medium'}
                    onClick={openPlayer}
                />
            </AuthPinChecker>
        )
    );
}
