import styles from './PersonDetails.module.scss';
import DetailTags from '@/components/detail-tags/DetailTags';
import { useState } from 'react';

/**
 * Renders a <Person /> component
 * @param props
 * @param {Person} props.model
 */
export default function PersonDetails({ model }) {
    const [href, setHref] = useState(model.getDetailsSrc());

    return (
        <article className={styles['person']}>
            <div className="person__wrap-img">
                <svg className="person__img" viewBox="0 0 354 354" fill="none">
                    <g filter="url(#filter0_d)">
                        <mask
                            id="mask-m3m7h0ree"
                            style={{ maskType: 'alpha' }}
                            maskUnits="userSpaceOnUse"
                            x="94"
                            y="90"
                            width="166"
                            height="166"
                        >
                            <path
                                d="M256.337 115.995C255.384 110.97 253.527 106.389 250.949 102.709C248.372 99.0283 245.164 96.3761 241.645 95.0165C228.763 90 176.916 90 176.916 90C176.916 90 125.069 90.152 112.187 95.1685C108.668 96.5281 105.46 99.1803 102.883 102.861C100.305 106.541 98.4476 111.122 97.4954 116.147C93.5989 148.83 92.0871 198.63 97.6019 230.006C98.554 235.03 100.411 239.611 102.989 243.291C105.567 246.972 108.775 249.624 112.294 250.984C125.175 256 177.022 256 177.022 256C177.022 256 228.869 256 241.751 250.984C245.27 249.624 248.478 246.972 251.056 243.291C253.633 239.611 255.491 235.03 256.443 230.006C260.553 197.277 261.819 147.507 256.337 115.995Z"
                                fill="#000029"
                            ></path>
                        </mask>
                        <g mask="url(#mask-m3m7h0ree)">
                            <path
                                d="M256.337 115.995C255.384 110.97 253.527 106.389 250.949 102.709C248.372 99.0283 245.164 96.3761 241.645 95.0165C228.763 90 176.916 90 176.916 90C176.916 90 125.069 90.152 112.187 95.1685C108.668 96.5281 105.46 99.1803 102.883 102.861C100.305 106.541 98.4476 111.122 97.4954 116.147C93.5989 148.83 92.0871 198.63 97.6019 230.006C98.554 235.03 100.411 239.611 102.989 243.291C105.567 246.972 108.775 249.624 112.294 250.984C125.175 256 177.022 256 177.022 256C177.022 256 228.869 256 241.751 250.984C245.27 249.624 248.478 246.972 251.056 243.291C253.633 239.611 255.491 235.03 256.443 230.006C260.553 197.277 261.819 147.507 256.337 115.995Z"
                                fill="#000029"
                            ></path>
                            <rect x="94" y="90" width="166" height="166" fill="url(#pattern0)"></rect>
                        </g>
                    </g>
                    <defs>
                        <filter
                            id="filter0_d"
                            x="-6.10352e-05"
                            y="0"
                            width="354"
                            height="354"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            ></feColorMatrix>
                            <feOffset dy="4"></feOffset>
                            <feGaussianBlur stdDeviation="47"></feGaussianBlur>
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.14902 0 0 0 0 0.4 0 0 0 0 1 0 0 0 0.4 0"
                            ></feColorMatrix>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                        </filter>
                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use xlinkHref="#image0" transform="scale(0.00609756)"></use>
                        </pattern>
                        {href && (
                            <image
                                id="image0"
                                width="164"
                                height="164"
                                href={href}
                                onError={() => /* NOSONAR, false positive for Invalid property */ {
                                    const stub = model.resources.getStubUrl('stub_40-40.png');
                                    if (href !== stub) {
                                        setHref(stub);
                                    }
                                }}
                            ></image>
                        )}
                    </defs>
                </svg>
            </div>
            <div className="person__info">
                <h1 className="person__title">{model.title}</h1>
                <DetailTags tags={Object.values(model.roles)} modifier={'person__tags detail-tags--no-wrap'} />
            </div>
        </article>
    );
}
