import styles from './Details.module.scss';
import * as FormatHelper from '@/src/helpers/FormatHelper';
import useCatchup from '@/src/hooks/useCatchup';
import Icon from '@/components/icon/Icon';
import DetailTags from '@/components/detail-tags/DetailTags';
import DetailStatus from '@/components/detail-status/DetailStatus';
import Button from '@/components/button/Button';
import AuthChecker from '@/src/checkers/AuthChecker';
import useModals from '@/src/hooks/useModals';
import { useApi } from '@/src/hooks/useApi';
import MultilineClamp from '@/components/multiline-clamp/MultilineClamp';
import Link from 'next/link';
import { useApiStore, useAuthStore } from '@/src/stores/RootStoreProvider';
import useChannelPackages from '@/src/hooks/useChannelPackages';
import useAuthCheck from '@/src/hooks/useAuthCheck';
import OfferNew from '@/components/offer-new/OfferNew';
import OfferSubscription from '@/components/offer-subscription/OfferSubscription';
import ModalCatchupAdvertising from '@/components/modal/purchases/ModalCatchupAdvertising';
import { STOCK_NOTICE } from '@/src/api/v3/models/Stock';

/**
 * Renders a <Schedule /> component
 * @param {Schedule} model
 */
export default function ScheduleDetails({ model }) {
    const { api } = useApiStore();
    const channelPackages = useChannelPackages();

    const channels = useApi(api.channels.getChannels);

    if (!channels || !channelPackages) {
        return;
    }

    return (
        <section className={styles['details'] + ' details--channel'}>
            <div className="details__wrapper">
                <div className="details__header">
                    <div className="details__aside">
                        <div className="details__poster">
                            <img
                                className={'details__poster-img'}
                                src={model.getImageSrc({ size: { width: 284, height: 160 } })}
                                alt={model.getProgramTitle()}
                            />
                            <Icon id={`age-${model.age}`} modifier={'icon--age-large details__poster-rating'} />
                        </div>
                    </div>
                    <div className="details__info">
                        <h1 className="details__info-title">{model.getProgramTitle()}</h1>
                        <TagsBlock model={model.program} />
                        <DetailStatus scores={model.program.scores} />
                        {model.channel && (
                            <div className="details__vendor">
                                <div className="details__vendor-label">{model.channel.title}</div>
                                <ChannelLogo model={model.channel} />
                            </div>
                        )}
                        <ScheduleBlock startDate={model.startAt} endDate={model.endAt} />
                    </div>
                </div>
                <ChannelPackageOfferBlock model={model} />
                <MultilineClamp
                    description={model.program.description}
                    buttonTitle={'Подробнее о передаче'}
                    key={`details-${model.id}`}
                />
            </div>
        </section>
    );
}

/**
 * Renders a <TagsBlock /> component
 * @param props
 * @param {Program} props.model
 */
function TagsBlock({ model }) {
    let tags = [];
    if (model.releasedYear) {
        tags.push(model.releasedYear);
    }
    if (model.genres.length) {
        tags.push(model.genres.join(', '));
    }
    if (model.countries.length) {
        tags.push(model.countries.join(', '));
    }
    return <DetailTags tags={tags} modifier={'details__tags detail-tags--group detail-tags--channel'} />;
}

/**
 * Renders a <ChannelLogo /> component
 * @param props
 * @param {Channel} props.model
 */
function ChannelLogo({ model }) {
    return (
        <article className="cover cover--player">
            <Link className="cover__wrapper cover__wrapper--int" href={model.getUrl()}>
                <div className="cover__poster">
                    <svg className="cover__channel" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask
                            style={{ maskType: 'alpha' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="60"
                            height="40"
                            id="mask-v0lu2qhy8"
                        >
                            <path
                                d="M58.6282 6.26374C58.2716 5.05303 57.5761 3.94926 56.6108 3.06237C55.6455 2.17548 54.4442 1.53641 53.1265 1.20879C48.3027 0 29.9686 0 29.9686 0C29.9686 0 11.6345 0.0366299 6.81056 1.24542C5.49287 1.57304 4.29157 2.21211 3.32631 3.099C2.36104 3.98589 1.6655 5.08966 1.30893 6.30037C-0.150195 14.1758 -0.716305 26.1758 1.3488 33.7363C1.70536 34.947 2.40091 36.0507 3.36617 36.9376C4.33144 37.8245 5.53273 38.4636 6.85043 38.7912C11.6743 40 30.0084 40 30.0084 40C30.0084 40 48.3425 40 53.1664 38.7912C54.4841 38.4636 55.6854 37.8245 56.6507 36.9376C57.6159 36.0507 58.3115 34.947 58.668 33.7363C60.2069 25.8498 60.6813 13.8571 58.6282 6.26374Z"
                                fill="#181F57"
                            ></path>
                        </mask>
                        <g mask="url(#mask-v0lu2qhy8)">
                            <rect
                                x="0"
                                y="0"
                                width="60"
                                height="40"
                                fill="<?= $model->channel->background_color ?>"
                            ></rect>
                            <image
                                href={model.getLogoSrc()}
                                width="100%"
                                height="100%"
                                preserveAspectRatio="xMinYMid slice"
                            ></image>
                        </g>
                    </svg>
                </div>
            </Link>
        </article>
    );
}

/**
 * Render ScheduleBlock
 * @param {Date} startDate
 * @param {Date} endDate
 */
function ScheduleBlock({ startDate, endDate }) {
    return (
        <div className="details__schedule">
            <div className="details__schedule-row">
                <div className="details__schedule-item">
                    <Icon id={'calendar'} modifier={'details__schedule-icon'} />
                    <span suppressHydrationWarning={true}>{FormatHelper.getDayMonth(startDate)}</span>
                </div>
                <div className="details__schedule-item">
                    <Icon id={'time'} modifier={'details__schedule-icon'} />
                    <span suppressHydrationWarning={true}>{FormatHelper.getPeriodTime(startDate, endDate)}</span>
                </div>
            </div>
        </div>
    );
}

/**
 * @param {Schedule} model
 */
function ChannelPackageOfferBlock({ model }) {
    const { api } = useApiStore();
    const { isGuest } = useAuthStore();
    const { openModal } = useModals();
    const catchupStatus = useCatchup();
    const authCheck = useAuthCheck();
    const channelPackages = useChannelPackages();
    const channels = useApi(api.channels.getChannels);

    if (!channels || !channelPackages) {
        return;
    }

    const channel = channels.find((channel) => channel.id === model.channel.id);
    if (!channel) {
        return '';
    }

    const isAvailable =
        channel.isAvailable() &&
        (model.isLive() ||
            (model.isCatchupAvailable &&
                !model.isFuture() &&
                model.isNotOldForCatchup() &&
                catchupStatus &&
                catchupStatus.isActive));

    const actionIfAvailable = (
        <AuthChecker condition={!model.channel.isAvailable()} backgroundSrc={model.getBackgroundSrc()}>
            <Button
                type="link"
                href={model.isLive() ? model.getLiveUrl() : model.getCatchUpUrl()}
                icon="play"
                className={'details__actions-btn button--large button--icon-left'}
                title="Смотреть"
            />
        </AuthChecker>
    );

    let offer;
    let channelPackage;
    channel?.packages?.slice(0, 1).forEach((channelChannelPackage) => {
        channelPackage = channelPackages.find((channelPackage) => channelPackage.id === channelChannelPackage.id);
        offer = channelPackage?.offers.offer;
    });

    let offerBlock;
    switch (true) {
        case channel?.isAvailable() &&
            model.isCatchup() &&
            !model.isCatchupAvailable &&
            catchupStatus &&
            catchupStatus.isActive:
            offerBlock = (
                <OfferNew
                    text="Запись передачи отсутствует"
                    url="/catchup-search"
                    info={
                        <>
                            К сожалению, каналы, входящие в состав первого и второго мультиплексов, запретили операторам
                            связи сохранять записи своих передач. Однако у нас есть множество других каналов, на которых
                            услуга «Управляй просмотром» работает как прежде.
                        </>
                    }
                    actions={[
                        <Button
                            title="Подробнее об услуге"
                            className="button--clear button--link"
                            type="link"
                            key="action"
                            href="/catchup-search"
                        />,
                    ]}
                    modifier={'offer-new__bold'}
                />
            );
            break;
        case channel?.isAvailable() &&
            model.isCatchup() &&
            model.isCatchupAvailable &&
            catchupStatus &&
            !catchupStatus.isActive:
            offerBlock = (
                <OfferNew
                    text="Смотрите в записи эту и другие передачи с услугой"
                    badgeText={catchupStatus?.stock?.getTitle()}
                    name="Управляй просмотром"
                    url="/catchup-search"
                    info={
                        <>
                            {getSubtitle(catchupStatus)} <br />
                            Смотрите любимые передачи в течение семи дней после выхода в эфир. Без рекламы, в удобное
                            для вас время.
                        </>
                    }
                    actions={[
                        <Button
                            key="action"
                            title="Подключить и смотреть"
                            className="button--large h-width--mobile-full"
                            onClick={() => openModal(<ModalCatchupAdvertising />)}
                        />,
                        <Button
                            key="action2"
                            className="button--large button--clear button--hint h-width--mobile-full"
                            title="Подробнее"
                            type="link"
                            href="/catchup-search"
                        />,
                    ]}
                />
            );
            break;
        case !offer && isGuest:
            offerBlock = (
                <Button
                    key={channelPackage.id}
                    title={'Зарегистрироваться'}
                    className={'h-mt--20 button--large'}
                    onClick={authCheck}
                />
            );
            break;
        case isAvailable && !channelPackage:
            offerBlock = actionIfAvailable;
            break;
        case Boolean(channelPackage):
            offerBlock = (
                <OfferSubscription
                    key={channelPackage.id}
                    model={channelPackage}
                    subscription={channelPackage}
                    modelPluralTypeName={'каналы'}
                    subscriptionTypeName={'пакете'}
                    action={isAvailable ? actionIfAvailable : ''}
                />
            );
            break;
        default:
        // Nothing
    }

    return <div className="details__offers">{offerBlock}</div>;
}

const getSubtitle = (catchupStatus) => {
    const stockSubtitle = catchupStatus.stock?.getTitle();
    return stockSubtitle ? (
        <>
            {stockSubtitle}, далее {catchupStatus.currentPrice} ₽ в месяц. {STOCK_NOTICE}
        </>
    ) : (
        <>Стоимость услуги {catchupStatus.currentPrice} ₽ в месяц.</>
    );
};
