import styles from './People.module.scss';
import PersonModel from '@/src/api/v3/models/Person';
import Person from '@/components/person/Person';
import Button from '@/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { throttle } from '@/src/modules/Common';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import { useApiStore } from '@/src/stores/RootStoreProvider';

class DummyPerson extends PersonModel {
    /**
     * Получение URL элемента
     * @returns string
     */
    getUrl() {
        return '#';
    }
}

const dummyPerson = new DummyPerson({});

/**
 * @param {Showcase[]} persons
 */
export default function People({ persons }) {
    const { api } = useApiStore();
    const [collapsed, setCollapsed] = useState(true);
    const [buttonVisible, setButtonVisible] = useState(false);
    const forceUpdate = useForceUpdate();
    const peopleRef = useRef(null);
    const maxListHeight = 160;

    const items = persons[0]?.items;
    const dummyCount = persons[0].total - items.length;

    const checkHeight = () => {
        if (peopleRef.current) {
            setButtonVisible(peopleRef.current.clientHeight > maxListHeight);
        }
    };

    useEffect(() => {
        const throttledCheckHeight = throttle(() => checkHeight());
        window.addEventListener('resize', throttledCheckHeight);
        return () => {
            window.removeEventListener('resize', throttledCheckHeight);
        };
    }, []);

    useEffect(() => {
        checkHeight();
    }, []);

    const onCollapseClick = () => {
        if (dummyCount) {
            persons[0].loadAllItems(api).then(() => {
                forceUpdate();
            });
        }
        setCollapsed(!collapsed);
    };

    return (
        <div
            className={`people details__people ${collapsed ? '' : 'people--visible'} ${
                buttonVisible ? '' : 'people--static'
            }`}
        >
            <h2 className={styles['people__title']}>Актёры и съёмочная группа</h2>
            <div className="people__inner">
                <div className="people__list" ref={peopleRef}>
                    <ul className="people__person">
                        {items.map((person) =>
                            person instanceof PersonModel ? <Person key={person.id} person={person} /> : ''
                        )}
                        {dummyCount > 0 &&
                            [...Array(dummyCount)].map((_, index) => (
                                <Person key={`dummy-${index}` /* NOSONAR */} person={dummyPerson} />
                            ))}
                    </ul>
                </div>
            </div>
            <Button
                title={collapsed ? 'Показать весь состав' : 'Скрыть'}
                icon={'arrow-down'}
                className={`people__more-btn button--icon-right button--large button--clear people__more-btn${
                    buttonVisible ? '' : ' h-hidden'
                }`}
                onClick={onCollapseClick}
            />
        </div>
    );
}
