import Asset from '@/src/api/v3/models/Asset';
import AssetAction from '@/components/v3/actions/AssetAction';

/**
 * @param {BaseModel} model
 * @param {Showcase} showcase
 * @param onShowcaseUpdate
 */
export const getModelAction = (model, showcase, onShowcaseUpdate) => {
    if (model instanceof Asset) {
        return <AssetAction model={model} showcase={showcase} onShowcaseUpdate={onShowcaseUpdate} />;
    }
    return null;
};
