import styles from './Details.module.scss';
import Button, { ButtonPrimary, ButtonTypes } from '@/components/button/Button';
import { ShowcaseItemType, Vote } from '@/src/constants/types';
import { useState } from 'react';
import OfferCard from '@/components/offer-card/OfferCard';
import { useApiStore, useAuthStore, usePinStore } from '@/src/stores/RootStoreProvider';
import DetailStatus from '@/components/detail-status/DetailStatus';
import DetailTags from '@/components/detail-tags/DetailTags';
import Icon from '@/components/icon/Icon';
import People from '@/components/people/People';
import Movie from '@/src/api/v3/models/Movie';
import Series from '@/src/api/v3/models/Series';
import useModals from '@/src/hooks/useModals';
import useAuthCheck from '@/src/hooks/useAuthCheck';
import ModalAuthorization from '@/components/modal/authorization/ModalAuthorization';
import AuthPinChecker from '@/src/checkers/AuthPinChecker';
import MultilineClamp from '@/components/multiline-clamp/MultilineClamp';
import { useRootModel } from '@/src/hooks/useRootModel';
import OfferSubscription from '@/components/offer-subscription/OfferSubscription';
import { clickButtonEvent } from '@/src/modules/events/GTM';
import usePurchases from '@/src/hooks/usePurchases';
import { PartnerSubscriptions } from '@/src/constants/subscriptions';
import OfferNew from '@/components/offer-new/OfferNew';
import { getPriceText } from '@/src/helpers/FormatHelper';

/**
 * Renders a <Details /> component
 * @param {Asset}
 */
export default function AssetDetails({ model }) {
    const offer = model.offers.getCheapestOffer();
    /** @type Subscription */
    const subscription = offer ? offer.subscription : null;

    return (
        <>
            <section
                className={
                    styles['details'] + ' ' + (model instanceof Movie ? 'details--card-film' : 'details--serial')
                }
            >
                <div className="details__wrapper">
                    <div className="details__header">
                        <div className="details__aside">
                            <div className="details__poster">
                                <img
                                    className="details__poster-img"
                                    src={model.getPosterImageSrc()}
                                    alt={model.title}
                                />
                                <Icon id={`age-${model.age}`} modifier={'icon--age-large details__poster-rating'} />
                                {subscription?.getBadgeSrc() && (
                                    <img
                                        className="details__poster-brand"
                                        src={subscription.getBadgeSrc({
                                            size: { width: '105', height: '30', func: 'contain' },
                                        })}
                                        alt={subscription.title}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="details__info">
                            <h1 className="details__info-title">{model.title}</h1>
                            <TagsBlock model={model} />
                            <DetailStatus scores={model.scores} modifier={'details__status'} />
                        </div>
                    </div>
                    {!model.offers.offer && (
                        <div className="details__offers">
                            <OfferNew text="По требованию правообладателя покупка контента в данный момент невозможна" />
                        </div>
                    )}
                    {model.offers.offer && model.isAvailable() && !subscription && (
                        <div className="details__offers">
                            <DetailsActionsBlock model={model} subscription={subscription} />
                        </div>
                    )}
                    {subscription && (
                        <div className="details__offers">
                            <OfferSubscription
                                model={model}
                                subscription={subscription}
                                modelPluralTypeName={model.typeName.toLowerCase() + 'ы'}
                                subscriptionTypeName="подписке"
                                action={<DetailsActionsBlock model={model} subscription={subscription} />}
                            />
                        </div>
                    )}
                </div>
                <UserActionsBlock model={model} key={model.id} />
                {!model?.offers?.offer?.subscription && <OffersBlock model={model} />}
                <MultilineClamp
                    description={model.description}
                    buttonTitle={`Подробнее о ${model.typeName.toLowerCase()}e`}
                    key={`details-${model.id}`}
                />
            </section>
            {model.persons.length > 0 && <People persons={model.persons} key={`persons-${model.id}`} />}
        </>
    );
}

/**
 * Renders a <TagsBlock /> component
 * @param props
 * @param {Asset} props.model
 */
function TagsBlock({ model }) {
    let tags = [];
    if (model.releasedAt) {
        tags.push(model.releasedAt.getFullYear());
    }
    if (model.viewable.duration) {
        tags.push(model.viewable.getDurationText());
    }
    if (model.genres.length) {
        tags.push(model.genres.join(', '));
    }
    if (model.countries.length) {
        tags.push(model.countries.join(', '));
    }
    return <DetailTags tags={tags} modifier={'details__tags'} />;
}

/**
 * Renders a <OffersBlock /> component
 * @param props
 * @param {Asset} props.model
 */
function OffersBlock({ model }) {
    if (model.isAvailable() || model.offers.isFree()) {
        return '';
    }
    return (
        <div className={styles['details__offers']}>
            {model.offers.offers.map((offer, index) => (
                <Offer key={offer.id} offer={offer} model={model} index={index} />
            ))}
        </div>
    );
}

/**
 * @param {Offer} offer
 * @param {Asset} model
 * @param {Number} index
 * @param {Boolean} isGuest
 * @returns {string}
 */
const getOfferButtonStyle = ({ offer, model, index, isGuest }) => {
    let buttonStyle = '';
    if (model.inPurchase()) {
        buttonStyle = ButtonTypes.TYPE_DISABLED;
    } else if (!model.offers.isTNBAvailable() && !isGuest) {
        buttonStyle = index === 0 ? '' : ButtonTypes.TYPE_SECONDARY;
    }
    if (model.offers.isTNBAvailable() || offer.subscription) {
        buttonStyle = buttonStyle + ' button--large';
    }
    return buttonStyle;
};

/**
 * Renders a <OffersBlock /> component
 * @param props
 * @param {Offer} props.offer
 * @param {Asset} props.model
 * @param {Number} props.index
 */
export function Offer({ offer, model, index }) {
    const authCheck = useAuthCheck();
    const { isGuest } = useAuthStore();
    const { onPurchaseModel } = usePurchases();

    const onPurchaseClick = () => {
        onPurchaseModel(model, offer);
    };

    const { buttonTitle, buttonIcon } = offer.getOfferButton(model.typeName, isGuest);
    let authTitle;
    if (offer.stock?.isTNBAvailable()) {
        authTitle = 'Чтобы активировать пробный период, надо сначала войти';
    } else if (model instanceof Movie) {
        authTitle = 'Чтобы купить или арендовать фильм, надо сначала войти';
    }

    let title, description;
    const priceText = getPriceText(offer.price, offer.period, offer.periodType, '');
    if (offer.ivodType === 'est') {
        title = `Купить ${model.typeName.toLowerCase()} за ${priceText}`;
        description = `После покупки ${model.typeName.toLowerCase()} будет доступен на всех ваших устройствах неограниченное количество времени. Смотреть его можно будет любое количество раз.`;
    } else {
        title = `Арендовать ${model.typeName.toLowerCase()} за ${priceText}`;
        description = `Вы можете арендовать этот фильм за ${offer.price} рублей. Будет 30 дней, чтобы начать просмотр и после этого 48 часов, чтобы его завершить. ${model.typeName} будет доступен на всех ваших устройствах.`;
    }

    return (
        <div className={styles['details__offers-item']}>
            <OfferCard
                label={title}
                text={description}
                action={
                    <Button
                        type="button"
                        className={getOfferButtonStyle({ offer, model, index, isGuest }) + ' h-width--mobile-full'}
                        icon={buttonIcon}
                        onClick={() =>
                            authCheck(onPurchaseClick, {
                                title: authTitle,
                                backgroundSrc: model.getBackgroundSrc(),
                            })
                        }
                        title={model.inPurchase() ? 'В процессе оплаты' : buttonTitle}
                    />
                }
                action2={
                    offer.subscription &&
                    (isGuest && offer.stock?.isTNBAvailable() ? (
                        <div className="button offer-card__more-btn offer-card__tnb-btn h-width--mobile-full">
                            <span className="button__content">
                                <span className="button__text">
                                    Бесплатный период доступен
                                    <br />
                                    только&nbsp;для&nbsp;новых&nbsp;пользователей
                                </span>
                            </span>
                        </div>
                    ) : (
                        <Button
                            type={'link'}
                            className={'button--transparent offer-card__more-btn h-width--mobile-full'}
                            title={'Подробнее'}
                            href={
                                model.type === ShowcaseItemType.CHANNEL_PACKAGE
                                    ? model.getUrl()
                                    : offer.subscription.getUrl()
                            }
                        />
                    ))
                }
                modifier={'offer-card--details'}
            />
        </div>
    );
}

/**
 * Renders a <UserActionsBlock /> component
 * @param {Asset} model
 */
function UserActionsBlock({ model }) {
    const { api } = useApiStore();
    const [isLike, setIsLike] = useState(model.estimate === Vote.LIKE);
    const [isDislike, setIsDislike] = useState(model.estimate === Vote.DISLIKE);
    const [isFavorite, setIsFavorite] = useState(model.isFavorite);

    const authCheck = useAuthCheck();

    const onButtonLikeClick = () => {
        model.estimate = isLike ? Vote.NEUTRAL : Vote.LIKE;
        setIsLike(model.estimate === Vote.LIKE);
        setIsDislike(model.estimate === Vote.DISLIKE);
        // noinspection JSIgnoredPromiseFromCall
        api.votes.voteSet(model.id, model.type, model.estimate);
    };

    const onButtonDislikeClick = () => {
        model.estimate = isDislike ? Vote.NEUTRAL : Vote.DISLIKE;
        setIsLike(model.estimate === Vote.LIKE);
        setIsDislike(model.estimate === Vote.DISLIKE);
        // noinspection JSIgnoredPromiseFromCall
        api.votes.voteSet(model.id, model.type, model.estimate);
    };

    const onButtonFavoriteClick = () => {
        model.isFavorite = !model.isFavorite;
        setIsFavorite(model.isFavorite);
        if (model.isFavorite) {
            // noinspection JSIgnoredPromiseFromCall
            api.favorites.addToFavorites(model.id, model.type);
        } else {
            // noinspection JSIgnoredPromiseFromCall
            api.favorites.removeFromFavorites(model.id, model.type);
        }
    };

    return (
        <div className="details__user-actions">
            <div className="details__user-actions-item">
                <Button
                    type={'button'}
                    icon={'like'}
                    className={
                        'details__user-actions-btn button--large button--icon-only button--special ' +
                        (isLike ? 'button--active' : 'button--secondary')
                    }
                    subtitle={'Понравилось'}
                    onClick={() => authCheck(onButtonLikeClick)}
                />
            </div>
            <div className="details__user-actions-item">
                <Button
                    type={'button'}
                    icon={'dislike'}
                    className={
                        'details__user-actions-btn button--large button--icon-only button--special ' +
                        (isDislike ? 'button--active' : 'button--secondary')
                    }
                    subtitle={'Не понравилось'}
                    onClick={() => authCheck(onButtonDislikeClick)}
                />
            </div>
            <div className="details__user-actions-item">
                <Button
                    type={'button'}
                    icon={'love'}
                    className={
                        'details__user-actions-btn button--large button--icon-only button--special ' +
                        (isFavorite ? 'button--active' : 'button--secondary')
                    }
                    subtitle={isFavorite ? 'Удалить из избранного' : 'В избранное'}
                    onClick={() => authCheck(onButtonFavoriteClick)}
                />
            </div>
        </div>
    );
}

/**
 * @param {Asset} model
 * @param {Subscription} subscription
 */
function DetailsActionsBlock({ model, subscription }) {
    const { isGuest } = useAuthStore();
    const { openModal } = useModals();
    const { pinStatus, checkIsPinExpired } = usePinStore();
    const { rootShowcase, onShowcaseUpdate } = useRootModel();
    const { rootModel } = useRootModel() || model;

    if (!model.isAvailable() && !model.offers.isFree()) {
        return '';
    }

    let playButtonTitle;
    let eventSubtype;
    let /** @type {?Episode} */ episode;
    if (model instanceof Series) {
        if (model.episode) {
            episode = model.episode;
            if (episode.viewable.position > 0) {
                playButtonTitle = `Досмотреть ${episode.number} серию ${episode.season?.number} сезона`;
                eventSubtype = 'continueWatch';
            } else {
                playButtonTitle = `Смотреть ${episode.number} серию ${episode.season?.number} сезона`;
                eventSubtype = 'watch';
            }
        } else {
            // noinspection JSValidateTypes
            episode = model.seasons?.showcases[0]?.items[0]?.episodes?.showcases[0]?.items[0];
            if (model.isAvailable() || model.offers.isFree()) {
                const subscription = model.offers?.getSubscription();
                playButtonTitle = subscription?.isPartner()
                    ? PartnerSubscriptions[subscription.id].watchTitle
                    : 'Смотреть';
                eventSubtype = 'watch';
            } else {
                playButtonTitle = 'Подключить и смотреть';
            }
        }
    } else if (model.isAvailable() || model.offers.isFree()) {
        if (model.viewable.isProgress() > 0) {
            playButtonTitle = 'Продолжить просмотр';
            eventSubtype = 'continueWatch';
        } else {
            const subscription = model.offers?.getSubscription();
            playButtonTitle = subscription?.isPartner() ? PartnerSubscriptions[subscription.id].watchTitle : 'Смотреть';
            eventSubtype = 'watch';
        }
    }

    if (model instanceof Series && !episode) {
        return '';
    }

    const openPlayer = async () => {
        if (isGuest) {
            openModal(<ModalAuthorization />);
        } else {
            if (subscription?.isPartner()) {
                window.open(model.shareLink, '_blank');
            } else {
                const ModalPlayer = (await import('@/components/modal/player/ModalPlayer')).default;
                openModal(
                    <ModalPlayer
                        asset={model}
                        rootModel={model}
                        rootShowcase={rootShowcase}
                        onShowcaseUpdate={onShowcaseUpdate}
                        setViewableStatus={setViewableStatus}
                    />
                );
            }
        }
    };

    const openEpisodePlayer = async (asset) => {
        if (subscription?.isPartner()) {
            window.open(asset.shareLink || model.shareLink, '_blank');
        } else {
            const ModalPlayer = (await import('@/components/modal/player/ModalPlayer')).default;
            openModal(<ModalPlayer asset={asset} rootModel={rootModel} setAsset={openEpisodePlayer} />, false);
        }
    };

    const setViewableStatus = (position) => {
        model.viewable.position = position;
    };

    const getViewPinReason = () => {
        return model.getViewPinReason(pinStatus, checkIsPinExpired());
    };

    return (
        <AuthPinChecker
            checked={getViewPinReason}
            capturedEventType={'onClickCapture'}
            backgroundSrc={model.getBackgroundSrc()}
            onBeforeCheck={() => {
                if (eventSubtype) {
                    clickButtonEvent(eventSubtype);
                }
            }}
        >
            <ButtonPrimary
                type={'button'}
                className={'h-width--mobile-full details__actions-btn button--large'}
                title={playButtonTitle}
                icon={'play'}
                onClick={model instanceof Movie ? openPlayer : () => openEpisodePlayer(episode)}
            />
        </AuthPinChecker>
    );
}
