import styles from '@/components/info/Info.module.scss';
import { DetailsTitle } from '@/components/v3/details/common/DetailsTitle';
import { DetailsOffer } from '@/components/v3/details/common/DetailsOffer';
import { DetailsActions } from '@/components/v3/details/common/DetailsActions';

/**
 * Renders a <BundleDetails /> component
 * @param {Bundle} model
 */
export default function BundleDetails({ model }) {
    return (
        <section className={styles['info'] + ' info--subscription'}>
            <div className="info__body">
                <div className="info__content">
                    <DetailsTitle model={model} />
                    <DetailsOffer model={model} />
                    <p className="info__description">{model.description}</p>
                    <div className="info__description info__description--extra">
                        {model.bundleInfo.map((info, index) => (
                            <InfoBlock
                                key={index /* NOSONAR */}
                                img={`https://er-cdn.ertelecom.ru/content/public/r${info.resourceId}/48x48:contain`}
                                title={info.title}
                            />
                        ))}
                    </div>
                    <DetailsActions model={model} />
                </div>
            </div>
        </section>
    );
}

function InfoBlock({ img, title }) {
    return (
        <div className="info__block">
            <img src={img} alt={title} />
            <span>{title}</span>
        </div>
    );
}
