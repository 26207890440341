import { useAuthStore } from '@/src/stores/RootStoreProvider';
import Button, { ButtonSecondary } from '@/components/button/Button';
import useAuthCheck from '@/src/hooks/useAuthCheck';
import usePurchases from '@/src/hooks/usePurchases';
import { PartnerSubscriptions } from '@/src/constants/subscriptions';
import Bundle from '@/src/api/v3/models/Bundle';

/**
 * Renders a <DetailsActions /> component
 * @param {Subscription} model
 */
export function DetailsActions({ model }) {
    const { isGuest } = useAuthStore();
    return (
        <div className="info__actions">
            <AvailableButton model={model} />
            {!isGuest && !model.isAvailable() && model.getPartnerTitle() && (
                <PartnerButton title={model.getPartnerTitle()} href={PartnerSubscriptions[model.id].link} />
            )}
            {model.isAvailable() && model.isIncludesKinopoisk() && (
                <PartnerButton title={'Активировать подписку Плюс'} href={'https://plus.yandex.ru/domru'} />
            )}
            {isGuest && model.offers.isTNBAvailable() && !(model instanceof Bundle) && (
                <div className="info__actions-item">
                    <div className="button offer-card__more-btn offer-card__tnb-btn h-width--mobile-full">
                        <span className="button__content">
                            <span className="button__text">
                                Бесплатный период доступен
                                <br />
                                только&nbsp;для&nbsp;новых&nbsp;пользователей
                            </span>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

/**
 * @param {Subscription} model
 */
function AvailableButton({ model }) {
    const { isGuest } = useAuthStore();
    const authCheck = useAuthCheck();
    const { onPurchaseModel } = usePurchases();

    const onPurchaseClick = () => {
        onPurchaseModel(model);
    };

    const offer = model.offers.offer;
    if (model.isAvailable() || !offer) {
        return '';
    }
    const { buttonTitle, buttonIcon } = offer.getDetailsButton('', isGuest);
    return (
        <div className="info__actions-item">
            <Button
                type={'button'}
                icon={buttonIcon}
                className={'button--large info__actions-btn' + (buttonIcon ? ' button--icon-left' : '')}
                title={buttonTitle}
                onClick={() => authCheck(onPurchaseClick, { backgroundSrc: model.getBackgroundSrc() })}
            />
        </div>
    );
}

/**
 * @param {String} title
 * @param {String} href
 */
function PartnerButton({ title, href }) {
    return (
        <div className="info__actions-item">
            <ButtonSecondary
                type={'link'}
                className={'button--large info__actions-btn'}
                title={title}
                href={href}
                target="_blank"
                rel="nofollow noopener noreferrer"
            />
        </div>
    );
}
