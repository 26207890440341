import styles from '@/components/info/Info.module.scss';
import { DetailsTitle } from '@/components/v3/details/common/DetailsTitle';
import { DetailsOffer } from '@/components/v3/details/common/DetailsOffer';
import { DetailsActions } from '@/components/v3/details/common/DetailsActions';

/**
 * Renders a <SubscriptionDetails /> component
 * @param {Subscription} model
 */
export default function SubscriptionDetails({ model }) {
    return (
        <section className={styles['info'] + ' info--subscription'}>
            <div className="info__body">
                <div className="info__content">
                    <DetailsTitle model={model} />
                    <DetailsOffer model={model} />
                    <p className="info__description">{model.description}</p>
                    <DetailsActions model={model} />
                </div>
            </div>
        </section>
    );
}
