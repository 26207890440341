import { ScoresTypes } from '@/src/api/v3/models/Score';
import { secondsToDuration } from '@/src/helpers/TimeHelper';
import Series from '@/src/api/v3/models/Series';
import { getPersonsMarkup } from '@/src/api/v3/models/helpers/MarkupHelper';

/**
 * @see https://schema.org/Movie
 * @param {Asset} model
 */
export const AssetMarkup = (model) => {
    let markup = {
        '@context': 'https://schema.org',
        '@type': model instanceof Series ? 'TVSeries' : 'Movie',
        name: model.title,
        description: model.description,
        url: 'https://movix.ru' + model.getUrl(),
        image: model.getPosterImageSrc(),
        datePublished: model.releasedAt ? model.releasedAt.getFullYear() : '',
        genre: model.genres,
        duration: model.viewable.duration ? secondsToDuration(model.viewable.duration) : '',
        contentRating: `${model.age}+`,
        countryOfOrigin: model.countries,
    };
    if (model.getTrailerSrc()) {
        markup.trailer = {
            '@type': 'VideoObject',
            description: model.description,
            embedUrl: model.getTrailerSrc(),
            name: model.title,
        };
    }
    const personMarkup = model.persons.length > 0 ? getPersonsMarkup(model.persons[0].items) : {};
    if (model.scores[ScoresTypes.SCORE_KINOPOISK]) {
        markup.aggregateRating = {
            '@type': 'AggregateRating',
            bestRating: 10,
            ratingValue: model.scores[ScoresTypes.SCORE_KINOPOISK],
        };
    }

    return {
        ...markup,
        ...personMarkup,
    };
};
