import styles from '@/components/info/Info.module.scss';

/**
 * Renders a <DetailsTitle /> component
 * @param {Subscription} model
 */
export function DetailsTitle({ model }) {
    return (
        <h2 className="info__title">
            {model.typeName}
            <span className={styles['info__logotype']}>
                {model.getCircleBadgeSrc() && (
                    <img
                        src={model.getCircleBadgeSrc({
                            size: { width: '48', height: '48', func: 'contain' },
                        })}
                        alt={model.title}
                    />
                )}
                {model.title}
            </span>
            {!model.isAvailable() && model.offers.isTNBAvailable() && (
                <>
                    <br />
                    <span className={'h-label--yellow'}>Попробуйте {model.offers.offer.stock?.getPeriodText()}</span>
                </>
            )}
        </h2>
    );
}
