import styles from './OfferCard.module.scss';

export default function OfferCard({ label, text, action, action2, modifier }) {
    return (
        <div className={styles['offer-card'] + (modifier ? ` ${modifier}` : '')}>
            <div className={styles['offer-card__content']}>
                <div className={styles['offer-card__label']}>{label}</div>
                <div className={styles['offer-card__text']} style={{ whiteSpace: 'pre-wrap' }}>
                    {text}
                </div>
            </div>
            <div className={styles['offer-card__action']}>
                <div className="offer-card__action-inner">
                    <div className={styles['offer-card__action-item']}>{action}</div>
                    <div className={styles['offer-card__action-item']}>{action2}</div>
                </div>
            </div>
        </div>
    );
}
