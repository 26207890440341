import styles from './OfferNew.module.scss';

export default function OfferNew({ badgeText, text, info, circleBadgeSrc = null, name, url, actions = [], modifier }) {
    return (
        <div className={styles['offer-new'] + (modifier ? ` ${modifier}` : '')}>
            <div className="offer-new__content">
                {badgeText && <span className="offer-new__badge">{badgeText}</span>}
                <span className="offer-new__text">{badgeText ? lowerCaseFirstLetter(text) : text}</span>
                <a href={url} className="offer-new__logotype">
                    {circleBadgeSrc && <img src={circleBadgeSrc} alt={name} />}
                    {name}
                </a>
            </div>
            <div className="offer-new__info" style={{ whiteSpace: 'pre-wrap' }}>
                {info}
            </div>
            <div className="offer-new__action">
                {actions.map((action, index) => (
                    <div key={index /* NOSONAR */} className="offer-new__action-item">
                        {action}
                    </div>
                ))}
            </div>
        </div>
    );
}

const lowerCaseFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
};
