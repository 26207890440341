import styles from './DetailStatus.module.scss';
import { ScoresTypes } from '@/src/api/v3/models/Score';

export default function DetailStatus({ scores, modifier }) {
    return (
        <div className={styles['detail-status'] + (modifier ? ' ' + modifier : '')}>
            {!!scores[ScoresTypes.SCORE_KINOPOISK] && (
                <div className="detail-status__item" aria-label="Кинопоиск">
                    <picture className="detail-status__img">
                        <source media="(max-width: 800px)" srcSet="/assets/img/icon-kp-xs.svg" />
                        <img src="/assets/img/icon-kp-lg.svg" alt="Кинопоиск" />
                    </picture>
                    <div className="detail-status__text"> {scores[ScoresTypes.SCORE_KINOPOISK].toFixed(2)}</div>
                </div>
            )}
            {!!scores[ScoresTypes.SCORE_IMDB] && (
                <div className="detail-status__item" aria-label="Интернет-база кинофильмов">
                    <picture className="detail-status__img">
                        <source media="(max-width: 800px)" srcSet="/assets/img/icon-imdb-xs.svg" />
                        <img src="/assets/img/icon-imdb-lg.svg" alt="Кинопоиск" />
                    </picture>
                    <div className="detail-status__text"> {scores[ScoresTypes.SCORE_IMDB].toFixed(2)}</div>
                </div>
            )}
        </div>
    );
}
