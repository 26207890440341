import PersonModel from '@/src/api/v3/models/Person';
import Asset from '@/src/api/v3/models/Asset';
import Schedule from '@/src/api/v3/models/Schedule';
import { AssetMarkup } from '@/src/api/v3/models/markup/AssetMarkup';
import { ScheduleMarkup } from '@/src/api/v3/models/markup/ScheduleMarkup';

const removeEmpty = (obj) => {
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== undefined && delete obj[k]);
    return obj;
};

const actorsMapping = {
    director: 'Режиссер',
    musicBy: 'Композитор',
    actor: 'Актер',
    producer: 'Продюсер',
};

/** @param {BaseModel[]} items */
export function getPersonsMarkup(items) {
    let markup = {};
    let persons = {};
    for (const key in actorsMapping) {
        persons[key] = [];
    }
    items.forEach((person) => {
        if (person instanceof PersonModel) {
            const personMarkup = {
                '@type': 'Person',
                name: person.title,
            };
            const roles = person.getRolesTitles();
            for (const [key, value] of Object.entries(actorsMapping)) {
                if (roles.includes(value)) {
                    persons[key].push(personMarkup);
                }
            }
        }
    });
    for (const key in actorsMapping) {
        const personsKey = persons[key];
        if (personsKey.length > 0) {
            markup[key] = personsKey.length === 1 ? personsKey[0] : personsKey;
        }
    }
    return markup;
}

/**
 * @param {BaseModel} model
 */
export default function getModelMarkup(model) {
    let json = null;
    if (model instanceof Asset) {
        json = AssetMarkup(model);
    }
    if (model instanceof Schedule) {
        json = ScheduleMarkup(model);
    }
    return json ? removeEmpty(json) : null;
}
