import styles from './DetailTags.module.scss';

export default function DetailTags({ tags, modifier }) {
    return (
        <ul className={styles['detail-tags'] + (modifier ? ' ' + modifier : '')}>
            {tags.map((tag, index) => (
                <li key={index /* NOSONAR */} className="detail-tags__item">
                    {tag}
                </li>
            ))}
        </ul>
    );
}
