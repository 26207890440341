import { useAuthStore } from '@/src/stores/RootStoreProvider';
import Button, { ButtonSecondary, ButtonTypes } from '@/components/button/Button';
import usePurchases from '@/src/hooks/usePurchases';
import OfferNew from '@/components/offer-new/OfferNew';
import AuthChecker from '@/src/checkers/AuthChecker';
import Movie from '@/src/api/v3/models/Movie';
import { STOCK_NOTICE } from '@/src/api/v3/models/Stock';

/** @param {Asset|Subscription} model
 * @param {Subscription} subscription
 * @param {String} subscriptionTypeName
 * @param {String} modelPluralTypeName
 * @param {?Object} action
 */
export default function OfferSubscription({
    model,
    subscription,
    modelPluralTypeName,
    subscriptionTypeName,
    action = null,
}) {
    const { isGuest } = useAuthStore();
    const { onPurchaseModel } = usePurchases();
    const offer = model.offers.offer;
    const isAvailable = model.isAvailable() || model.offers.isFree();
    const isTNBAvailable = model.offers.isTNBAvailable() && !model.isAvailable();

    let actions = [];
    if (isAvailable && action) {
        actions.push(action);
        if (subscription.isIncludesKinopoisk()) {
            actions.push(
                <ButtonSecondary
                    type={'link'}
                    className={'button--large info__actions-btn'}
                    title={'Активировать подписку Плюс'}
                    href={'https://plus.yandex.ru/domru'}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                />
            );
        }
    }
    if (!isAvailable) {
        let buttonTitle;
        if (isGuest) {
            buttonTitle = 'Зарегистрироваться';
        } else {
            buttonTitle = model.inPurchase() ? 'В процессе оплаты' : 'Подключить и смотреть';
        }

        let authTitle;
        if (offer.stock?.isTNBAvailable()) {
            authTitle = 'Чтобы активировать пробный период, надо сначала войти';
        } else if (model instanceof Movie) {
            authTitle = 'Чтобы купить или арендовать фильм, надо сначала войти';
        }

        const buttonClassName = model.inPurchase() ? ButtonTypes.TYPE_DISABLED : '';

        actions.push(
            <AuthChecker title={authTitle} backgroundSrc={model.getBackgroundSrc()}>
                <Button
                    title={buttonTitle}
                    className={'button--large h-width--mobile-full' + (buttonClassName ? ' ' + buttonClassName : '')}
                    onClick={() => onPurchaseModel(model)}
                />
            </AuthChecker>
        );
        if (!isGuest) {
            if (subscription.getPartnerTitle()) {
                actions.push(
                    <ButtonSecondary
                        type={'link'}
                        className={'button--large h-width--mobile-full'}
                        title={subscription.getPartnerTitle()}
                        href={model.shareLink}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    />
                );
            }
            actions.push(
                <Button
                    className="button--large button--clear button--hint h-width--mobile-full"
                    title="Подробнее"
                    type="link"
                    href={subscription.getUrl()}
                />
            );
        }
    }

    return (
        <OfferNew
            badgeText={isTNBAvailable ? `Попробуйте ${offer.stock.getPeriodText()}` : ''}
            text={`${isTNBAvailable ? 'этот' : 'Этот'} и другие ${modelPluralTypeName} в ${subscriptionTypeName}`}
            name={subscription.title}
            url={subscription.getUrl()}
            circleBadgeSrc={subscription.getCircleBadgeSrc()}
            info={
                isTNBAvailable && (
                    <>
                        {offer.getStockInfo()} {STOCK_NOTICE}
                    </>
                )
            }
            actions={actions}
        />
    );
}
